<template>
  <v-app>
    <TheAlerts />
    <components
      :is="invoicePageBasedOnCompanyType"
      :invoice="invoice"
      :terms="terms"
      :is-submitting="isSubmitting"
      :currency-type="currencyType"
      :exchange-rate="exchangeRate"
      :payment-gateway="paymentGateway"
      @submit="submit"
    />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import invoices from '@/services/invoices'
import TheAlerts from '@/components/TheAlerts.vue'
import InvoiceProviderForm from '@/components/InvoiceProviderForm.vue'
import InvoiceProviderConfirmation from '@/components/InvoiceProviderConfirmation.vue'

export default {
  metaInfo() {
    return {
      title: `Invoice${
        this.invoice && this.invoice.company.name
          ? ' - ' + this.invoice.company.name
          : ''
      }`,
      titleTemplate: '%s',
    }
  },
  components: {
    TheAlerts,
    InvoiceProviderForm,
    InvoiceProviderConfirmation,
  },
  data() {
    return {
      invoice: null,
      terms: '',
      isSubmitting: false,
      invoiceNotFound: false,
      hasSubmittedSuccessfully: false,
      paymentGateway: undefined,
      exchangeRate: null,
      currencyType: 'USD',
      redirectsForBrokerTypes: {
        shofur: 'https://shofur.com/reservation/thank-you',
        national:
          'https://www.charterup.com/reservation-confirmation?name=National%20Charter%20Bus',
        gogo: 'https://gogocharters.com/thank-you',
      },
    }
  },
  computed: {
    invoicePageBasedOnCompanyType() {
      if (this.hasSubmittedSuccessfully) {
        return InvoiceProviderConfirmation
      }
      if (this.invoice) {
        return InvoiceProviderForm
      }
      return null
    },
  },
  async created() {
    const invoiceResponse = await invoices.getReservationInvoice({
      reservationHashes: this.$route.params.reservationHash.split(','),
    })

    if (invoiceResponse.status === 404) {
      this.invoiceNotFound = true
      return
    } else if (invoiceResponse?.data?.redirect?.length > 0) {
      window.open(invoiceResponse.data.redirect, '_self')
      return
    }

    let invoiceData = invoiceResponse?.data

    for (let i = 0; i < invoiceData.invoiceReservations.length; i++) {
      invoiceData.invoiceReservations[i].customerId =
        invoiceData.customer?.customerId
      invoiceData.invoiceReservations[i].companyCheckoutPageId =
        invoiceData.checkoutPage?.companyCheckoutPageId
    }
    this.invoice = invoiceData
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    submit() {
      this.hasSubmittedSuccessfully = true
    },
  },
}
</script>

<style lang="scss">
body,
#app {
  background: $white !important;
}
</style>
